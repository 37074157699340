<svg
  width="32.035019"
  height="31.965853"
  viewBox="0 0 8.4759316 8.4576318"
  version="1.1"
  id="svg4191"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
  class={$$props.class}
  fill="currentColor"
  stroke="currentColor"
>
  <defs id="defs4188" />
  <g id="layer1" transform="translate(0.00463201,-0.00451693)">
    <g
      id="g4703"
      transform="matrix(0.68088189,0,0,0.64722958,-14.592662,0.40513752)"
    >
      <path
        id="rect9846"
        style="display:inline;fill:none;stroke:currentColor;stroke-width:1.08479"
        d="M 21.967594,0.542395 H 33.331265 V 11.906066 H 21.967594 Z"
      />
      <path
        style="display:inline;fill:currentColor;fill-opacity:1;stroke-width:0.353;stroke-dasharray:none"
        d="m 30.187147,10.138692 c -3.21818,-4.0175496 -3.01441,-3.9353796 -5.07458,-2.0463796 -4.03864,3.7030996 -4.28539,3.6763596 -0.9908,-0.10736 1.03241,-1.18568 1.8771,-2.22601 1.8771,-2.31183 0,-0.0858 -0.79128,-0.54447 -1.7584,-1.01921 -1.93433,-0.94953 -2.0473,-1.18716 -1.13073,-2.37828 l 0.62296,-0.80956 1.45065,1.05084 c 0.79785,0.57796 1.56133,1.22995 1.69663,1.44886 0.33319,0.53911 1.85035,-1.5541 2.36047,-3.25671996 0.27287,-0.91077 0.60202,-1.32803 1.0476,-1.32803 1.07062,0 0.77611,1.39994 -0.80291,3.81656996 -1.45263,2.22318 -1.45263,2.22318 -0.75445,2.99567 1.35314,1.49715 3.64953,5.2880896 3.54188,5.8470396 -0.0716,0.3719 -0.78625,-0.27974 -2.08542,-1.90161 z"
        id="path6447"
      />
    </g>
  </g>
</svg>
