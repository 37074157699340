<svg
   width="28.995893"
   height="31.999998"
   viewBox="0 0 7.67183 8.4666661"
   version="1.1"
   id="svg-pdf-text-icon"
   class={$$props.class}
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs504" />
  <g
     id="layer1"
     transform="translate(-0.39741871)">
    <path
       d="M 0.39741874,0 V 8.466666 H 1.3535184 V 5.0799995 C 1.6990966,5.0684802 2.0446748,4.9417683 2.2635409,4.59619 2.516965,4.1930155 2.6667156,3.4903397 2.6667156,2.53424 2.6667156,1.5781402 2.516965,0.9100225 2.2635409,0.50684742 2.0101169,0.11519274 1.7451736,0 1.3650377,0 Z M 1.3535184,3.8128794 v -2.568798 c 0.1036735,0 0.2419047,0 0.2995011,0.1267121 0.092154,0.2073467 0.092154,0.7372339 0.092154,1.2095236 0,0.4722902 0,0.9100226 -0.092154,1.0943311 C 1.5954226,3.7898409 1.4456726,3.8128794 1.3535184,3.8128794 Z M 3.1735546,8.466666 h 0.9215418 c 0.7602722,0 1.0367347,-0.2995011 1.1864853,-0.7487527 C 5.4543708,7.2110652 5.4543708,5.897868 5.4543708,4.2390926 5.4543708,2.5803171 5.431332,1.5205444 5.2355045,0.87546438 5.0281577,0.23038547 4.7286565,0 3.9683845,0 H 3.1735546 Z M 4.1296543,7.2225845 V 1.2440814 c 0.1382312,0.01152 0.3110204,-0.02304 0.3455782,0.276463 0.057597,0.5183667 0.034559,1.3362352 0.034559,3.0756456 v 1.9813152 c 0,0.6796371 -0.2303856,0.6450793 -0.3801372,0.6450793 z M 8.0692488,1.2786395 V 0 H 6.0879338 V 8.466666 H 7.0440335 V 4.7804984 H 7.7351899 V 3.5018591 H 7.0440335 V 1.2786395 Z"
       id="text1"
       style="font-stretch:ultra-condensed;font-size:11.2889px;font-family:'League Gothic';-inkscape-font-specification:'League Gothic, Ultra-Condensed';text-align:center;letter-spacing:0px;word-spacing:0px;text-anchor:middle;stroke-width:3.06122;stroke-linecap:round;stroke-linejoin:round"
       aria-label="PDF" />
  </g>
</svg>
