<script>
  import AnchorExternal from '../../shared/AnchorExternal.svelte'

</script>
<h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
<h2>
  MISE À DISPOSITION DU SITE WEB ET CRÉATION DE FICHIERS JOURNAUX
</h2>
<p>
  Afin de respecter ses obligations légales, l'hébergeur du site web (HODI)
  collecte et conserve des données techniques relatives à l’utilisation des
  services (IP, User Agent) pour une durée d'un an.
</p>
<h2>UTILISATION DES COOKIES</h2>
<p>topmaths n'utilise aucun cookie.</p>
<h2>STOCKAGE DES DONNÉES</h2>
<p>
  topmaths ne récupère aucune donnée de ses utilisateurs.<br />
  Certaines données sont stockées localement dans le navigateur mais ne sont à
  aucun moment envoyées sur internet.
</p>
<h2>
  UTILISATION DE SERVICES TIERS
</h2>
<p>
  topmaths affiche des éléments de services tiers qui peuvent éventuellement
  récupérer des données personnelles.
</p>
<br />
<ul>
  <li>
    Le calcul mental peut venir de
    <AnchorExternal href="https://mathsmentales.net/cgu.html">
      MathsMentales
    </AnchorExternal>
  </li>
  <li>
    Les vidéos viennent de
    <AnchorExternal href="https://policies.google.com/privacy?hl=fr#infocollect">
      YouTube
    </AnchorExternal>
    avant d'être filtrées par
    <AnchorExternal href="https://ladigitale.dev/confidentialite.html">
      DigiView
    </AnchorExternal>
  </li>
  <li>
    Certains exercices peuvent venir de :
    <ul>
      <li>
        <AnchorExternal href="https://mathix.org/linux/politique-de-confidentialite-2">
          mathix
        </AnchorExternal>
      </li>
      <li>
        <AnchorExternal href="https://www.sesamath.net/index.php?page=mentions_legales">
          Sesamath
        </AnchorExternal>
      </li>
      <li>
        <AnchorExternal href="https://www.geogebra.org/privacy">
          Geogebra
        </AnchorExternal>
      </li>
      <li>
        <AnchorExternal href="https://www.clicmaclasse.fr/">
          clicmaclasse
        </AnchorExternal>
      </li>
    </ul>
  </li>
</ul>
<h2>
  DROIT D’ACCÈS AUX DONNÉES
</h2>
<p>
  <b>Information et consultation du dossier :</b> conformément à l'Article 15
  du Règlement général sur la protection des données (Cnil.fr), les
  utilisateurs du site et des applications de La Digitale peuvent formuler une
  demande de consultation ou d’accès aux données les concernant, dans la
  mesure ou ces données peuvent être rattachées de manière univoque et sans
  ambiguïté à leur personne physique.
  <br /><br />
  <b>Correction :</b> Si les données personnelles enregistrées sont
  incorrectes ou incomplètes, vous avez le droit de les faire corriger ou
  compléter conformément à l'Article 16 du Règlement général sur la protection
  des données (Cnil.fr).
  <br /><br />
  <b>Suppression :</b> l'Article 17 du Règlement général sur la protection des
  données (Cnil.fr) normalise le droit à la suppression des données
  personnelles. Vous disposez de ce droit en particulier si la conservation
  des données à caractère personnel n'est plus nécessaire à l'accomplissement
  des tâches légales ou si vous avez révoqué votre consentement au traitement
  des données avec effet pour l'avenir.
  <br /><br />
  <b>Restriction du traitement :</b> conformément à l'Article 18 du Règlement
  général sur la protection des données (Cnil.fr), vous pouvez demander la
  restriction des données personnelles si vous contestez l'exactitude des
  données ou si le traitement est illégal.
  <br /><br />
  <b>Opposition :</b> vous pouvez faire valoir un droit d'opposition pour des
  raisons liées à votre situation particulière. Toutefois, conformément à
  l'Article 21 du Règlement général sur la protection des données (Cnil.fr),
  il convient d'examiner s'il existe des motifs légitimes pour le traitement
  ou si le traitement sert à faire valoir, à exercer ou à défendre des droits
  légaux.
  <br /><br />
  <b>Portabilité des données :</b> si le traitement de vos données a été
  effectué à l'aide d'une procédure automatisée, vous avez le droit,
  conformément à l'Article 20 du Règlement général sur la protection des
  données (Cnil.fr), de recevoir les données dans un format commun et lisible
  par machine et de les transférer ou de les faire transférer à un autre
  responsable.
  <br /><br />
  <b>Retrait du consentement :</b> conformément à l'Article 7, paragraphe 3,
  du Règlement général sur la protection des données (Cnil.fr), vous avez le
  droit de révoquer le consentement que vous nous avez donné à tout moment
  avec effet pour l'avenir.
  <br /><br />
  <b>Contact pour le traitement des données :</b> Guillaume VALMONT (contact arobase
  topmaths.fr).
</p>
