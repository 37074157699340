<script lang="ts">
  import type { Vue } from '../../../types'

  export let slideView: Vue

</script>

<div>
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  {@html slideView.correction}
</div>
