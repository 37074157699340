<a
  href="https://9dac7f4d.sibforms.com/serve/MUIFAOLWGS54qMbFsT261mYM712Pc5Q5tn1oK7m7m1iWp4l7Ci-ZP4Jl5DsiM3e4hqkUWHbMttY-iX5bndWVuxoWMLVDmRaL584J2XG1sfc0gtmWcB8bfJqfWaHffFA8AdqY-eDeUPj5vOXSH-ObbaT9ERBtwHm5W1oXgZCquT_11t-X9AzzkXHTaGRWp50J0RMHXkpnmD-4JcRA"
  target="_blank"
  class="flex flex-col items-center mx-auto max-w-lg p-3 rounded-lg shadow
    bg-coopmaths-canvas-dark border border-gray-200 hover:bg-gray-200
    dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
>
  <h5 class="mb-2 text-2xl font-bold tracking-tight text-coopmaths-struct dark:text-white">
    Newsletter
  </h5>
  <div class="flex space-x-2">
    <img
      src="/alea/assets/images/newsletter.png"
      alt="Logo newsletter"
      class="max-w-36 mb-4 flex"
    />
  </div>
  <p class="text-center font-normal text-gray-700 dark:text-gray-400">
    Cliquez ici pour vous inscrire à la newsletter et recevoir des informations sur les nouveautés de MathALÉA.
  </p>
</a>
<!-- <a
  href="https://coopmaths.fr/www/partenariats/"
  target="_blank"
  class="mt-4 flex flex-col items-center mx-auto max-w-lg p-3 rounded-lg shadow
    bg-coopmaths-canvas-dark border border-gray-200 hover:bg-gray-200
    dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
>
  <div class="flex space-x-2">
    <img
      src="/alea/assets/images/sponsor.png"
      alt="Logos sponsors"
      class="max-w-72 mb-4 flex"
    />
  </div>
  <p class="text-center font-normal text-gray-700 dark:text-gray-400">
    MathALÉA est un logiciel libre développé par une communauté d'enseignants et soutenu par le Ministère de l'Éducation nationale, la DRANE d'Occitanie et la DRANE de Grenoble.
  </p>
</a> -->
<!-- <a
  href="https://coopmaths.fr/www/cahiers-de-vacances"
  target="_blank"
  class="flex flex-col items-center mx-auto max-w-lg p-3 rounded-lg shadow
    bg-coopmaths-canvas-dark border border-gray-200 hover:bg-gray-200
    dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
>
  <h5 class="mb-2 text-2xl font-bold tracking-tight text-coopmaths-struct dark:text-white">
    Nouveau
  </h5>
  <div class="flex space-x-2">
    <img
      src="/alea/assets/images/cahier_vers_la_2nde.png"
      alt="Cahier de vacances vers la première spécialité"
      class="max-w-36 mb-4 flex"
    />
    <img
      src="/alea/assets/images/cahier_vers_la_1ere_spe.png"
      alt="Cahier de vacances vers la première spécialité"
      class="max-w-36 mb-4 flex"
    />
    <img
      src="/alea/assets/images/cahier_vers_la_terminale.png"
      alt="Cahier de vacances vers la terminale spécialité"
      class="max-w-36 mb-4 flex"
    />
  </div>
  <p class="text-center font-normal text-gray-700 dark:text-gray-400">
    Découvrez les cahiers de vacances pour le lycée général.
  </p>
</a> -->
