<svg
  width="31.999998"
  height="31.999998"
  viewBox="0 0 8.4666661 8.4666661"
  version="1.1"
  id="svg2697"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
  class={$$props.class}
  fill="currentColor"
>
  <defs id="defs2694" />
  <g id="layer1">
    <path
      id="path5800"
      style="fill:currentColor;stroke:none;stroke-width:0.024561"
      d="M 5.6190408,1.3436103 C 3.1929106,1.6410426 2.0896442,1.8517723 -6.6666665e-8,3.0631178 L 0.01937674,3.1181452 l 0.1660648,0.00168 c -0.0151705,0.1672543 -0.0417532,0.580651 -0.00793,1.2022881 -0.2318563,0.6710048 -0.005861,1.1268667 0.20632167,1.622751 0.0336508,-0.51578 0.0301858,-1.0806985 -0.12822484,-1.6428984 -0.0331509,-0.6174618 -0.005861,-1.0234456 0.009137,-1.1813718 l 1.38524723,0.013274 a 6.3367299,6.3367299 0 0 0 0.041012,0.8119988 c 2.413e-4,7.41e-5 4.827e-4,1.465e-4 7.24e-4,2.465e-4 -0.019394,0.1136922 -0.029686,0.2337457 -0.029686,0.3610258 V 7.1230563 H 3.0168247 V 4.4640923 C 3.0166023,4.3152495 3.0333743,4.1870108 3.0661804,4.0780232 3.4148371,4.0207547 3.7437413,3.892485 4.0371069,3.6999667 4.2663963,3.8138486 4.3816797,4.0680313 4.3816797,4.4640923 v 2.658964 h 1.354546 V 4.4640923 C 5.7372083,3.9080313 5.9669011,3.6300947 6.425373,3.6302654 6.8838432,3.6304378 7.1130792,3.9083588 7.1130792,4.4640923 v 2.658964 H 8.4666667 V 4.3070919 C 8.466496,3.7267978 8.2649892,3.2877561 7.8621894,2.9899135 7.5078576,2.7238375 7.0286125,2.5907977 6.4244128,2.5907977 c -0.6608527,0 -1.1162405,0.1571004 -1.3661083,0.4713116 -0.066043,-0.071577 -0.1408956,-0.1345516 -0.2236867,-0.1898186 5e-5,-9.82e-5 1.207e-4,-1.982e-4 1.948e-4,-2.879e-4 C 4.6513449,2.6664276 4.2917878,2.3859638 4.2917878,2.3859638 L 5.636261,1.403525 Z"
    />
  </g>
</svg>
