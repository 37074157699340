<svg
  width="31.999998"
  height="31.999998"
  viewBox="0 2 32 28"
  version="1.1"
  id="svg2697"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
  class={$$props.class}
  stroke="currentColor"
>
<g fill="none" stroke="currentColor">
  <rect x="6" y="2" width="21" height="28" rx="4" ry="4" stroke-width="2"/>
  <path transform="matrix(.075075 .00043984 -.00043984 .075075 -2.3679 -12.924)" d="m294.47 523.45c-9.0804 8.8134-45.575-17.77-58.101-15.977-12.527 1.7928-40.099 37.545-51.287 31.633-11.188-5.9125 2.8168-48.836-2.7592-60.195-5.576-11.359-48.099-26.535-45.933-39.002s47.316-12.412 56.396-21.225c9.0804-8.8134 10.372-53.945 22.899-55.738 12.527-1.7928 26.426 41.164 37.614 47.077s54.51-6.805 60.086 4.5544c5.576 11.359-30.984 37.853-33.149 50.321-2.1659 12.467 23.316 49.739 14.236 58.552z" stroke-width="15.984"/>
  <path transform="matrix(.031314 -.025224 .025224 .031314 2.3208 .20682)" d="m294.47 523.45c-9.0804 8.8134-45.575-17.77-58.101-15.977-12.527 1.7928-40.099 37.545-51.287 31.633-11.188-5.9125 2.8168-48.836-2.7592-60.195-5.576-11.359-48.099-26.535-45.933-39.002s47.316-12.412 56.396-21.225c9.0804-8.8134 10.372-53.945 22.899-55.738 12.527-1.7928 26.426 41.164 37.614 47.077s54.51-6.805 60.086 4.5544c5.576 11.359-30.984 37.853-33.149 50.321-2.1659 12.467 23.316 49.739 14.236 58.552z" stroke-width="24.87"/>
 </g>
</svg>
